import './src/styles/global.css'

window.axeptioSettings = {
  clientId: '62a870754709b6eb7ae4829c',
}
;;(function (d, s) {
  var t = d.getElementsByTagName(s)[0],
    e = d.createElement(s)
  e.async = true
  e.src = '//static.axept.io/sdk-slim.js'
  t.parentNode.insertBefore(e, t)
})(document, 'script')

let _axcb
;(_axcb = window._axcb || []).push(function (sdk) {
  sdk.on('consent:saved', function (choices) {
    window.location.reload()
  })
})
